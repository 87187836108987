import React, {useEffect, useRef, useState} from "react";
import {Alert, Col, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {EmailAuthProvider, getAuth, reauthenticateWithCredential,} from "firebase/auth";
import {verifyCode} from "../functions/verify";
import {getUserFromEmail} from "../functions/getUser";
import {useCookies} from "react-cookie";
import LoadingButton from "./LoadingButton";
import ComponentCard from "./ComponentCard";
import PasswordInput from "./PasswordInput";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SelectSponsor() {
    const passwordRef = useRef("");
    const codeRef = useRef("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(["cancelService"]);
    const {generateEmailIndex, currentUser} = useAuth();
    const [disabled, setDisabled] = useState();
    const [haveSecret, setHaveSecret] = useState();
    const auth = getAuth();

    const navigate = useNavigate();

    useEffect(() => {
        postWithCredentials(SERVER_URL + "checkSecret", {
            user_id: auth.currentUser.uid,
        }).then((res) => {
            // console.log("this is secret ", res.data.secret);
            setHaveSecret(res.data.secret);
        });
    }, [auth.currentUser.uid]);

    const sendCancelServiceEmail = async (userEmail, userIndex) => {
        return await postWithCredentials(SERVER_URL + "sendCancelServiceEmail", {
            userEmail,
            userIndex,
        })
            .then(() => {
                // console.log('Confirmation Email Sent');
            })
            .catch((err) => {
                // console.log(err);
                throw new Error("Error sending confirmation email, please try again.");
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError("");
            setLoading(true);

            // Verify Password
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                passwordRef.current.value
            );
            try {
                await reauthenticateWithCredential(auth.currentUser, credential);
            } catch (e) {
                if ((e.code = "auth/too-many-requests")) {
                    setDisabled(true);
                    throw new Error(
                        "You have submitted the wrong password too many times. Please proceed to reset password to reset your password."
                    );
                }
                throw new Error("Password Incorrect");
            }
            // console.log("this is current user", auth.currentUser);
            if (haveSecret) {
                // Verify 2FA Code
                const verified = await verifyCode(
                    codeRef.current.value,
                    auth.currentUser.uid
                );
                if (!verified) {
                    setError("Incorrect Code.");
                    setLoading(false);
                    return;
                }
            }

            let subId;
            const userData = await getUserFromEmail(auth.currentUser.email);
            if (userData.subscription_id) {
                subId = userData.subscription_id;
            }

            await CancelSubscription(subId, userData.user_id);
            const index = generateEmailIndex(currentUser.email);
            await sendCancelServiceEmail(currentUser.email, index);
            setSuccess("Successfully Cancelled Service");
            setTimeout(() => {
                navigate("/service-usage-info");
            }, 2000);
        } catch (e) {
            setError(e.message);
            // console.log(e);
        }
        setLoading(false);
    };

    // const hasCanceled = () => {

    // }

    const CancelSubscription = async (subId, userId) => {
        return await postWithCredentials(SERVER_URL + "updateSubscription", {
            subId,
            userId,
            cancellingSubs: true,
        })
            .then(() => {
                // These line wait till 2 mins to allow next cancellation.
                var date = new Date();
                date.setTime(date.getTime() + 60 * 2000);
                setCookie("cancelService", "yes", {expires: date, path: "/"});
            })
            .catch((err) => {
                // console.log(err);
                throw new Error("Failed to cancel subscription");
            });
    };

    const helpContent = (
        <p className="mb-1">
            This page requires you to fill in your password and 2FA code obtained from
            your smartphone. Once you confirm that you want to cancel your
            subscription, your subscription will expire at the end of your billing
            period.
        </p>
    );

    return (
        <ComponentCard title="Cancel Service" helpContent={helpContent}>
            {success && <Alert variant="success">{success}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            {cookies.cancelService === "yes" ? (
                <p>
                    You have canceled your subscription recently. Please try again after
                    some time.
                </p>
            ) : (
                <div>
                    <p>
                        We're sorry to hear that you've decided to cancel your service. 
                        Any changes to your account will take
                        effect at the <strong>beginning of the next billing cycle</strong>. 
                        Please keep in mind that canceling your
                        service means that you'll be returned to the{" "}
                        <strong>FREE service</strong>, which only allows decrypting. We
                        appreciate your understanding and thank you for using our service.
                    </p>
                    <p>
                        To cancel your service, you must provide 2-Factor Authentication (2FA). 
                        If you haven't registered for 2FA yet, click <Link to="/set-google-auth">here</Link>.
                    </p>
                </div>
            )}
            <Form onSubmit={handleSubmit} className="w-100">
                <Form.Group id="sponsor_email" className="row mb-3">
                    <Col xs={12} sm={"auto"}>
                        <Form.Label column style={{width: "8ch"}}>
                            Password
                        </Form.Label>
                    </Col>
                    <Col>
                        <PasswordInput ref={passwordRef} placeholder="Password" required/>
                    </Col>
                </Form.Group>
                {haveSecret ? (
                    <Form.Group id="2FA_code" className="row mb-3">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "8ch"}}>
                                2FA Code
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control type="text" ref={codeRef} placeholder="2FA Code" required/>
                        </Col>
                    </Form.Group>
                ) : null}

                <LoadingButton
                    loading={loading}
                    disabled={loading || cookies.cancelService === "yes" || disabled}
                    type="submit"
                    className="w-100"
                >
                    Cancel Service
                </LoadingButton>
            </Form>
        </ComponentCard>
    );
}
