import CryptoJS from 'crypto-js';

/**
 * Number of bytes to read off of the beginning of the files for generating proofs and file identifiers
 */
const BYTES_TO_READ = 32;

const SLICE_SIZE = 10 * 1024 * 1024;

/**
 * Size of the initial value vector in bytes
 */
const IV_BYTES = 16;

export async function getFileIdentifier1(file) {
    async function onData(res, rej) {
        try {
            // Read the first slice 
            let slice = await readSlice(file, 0, SLICE_SIZE);
            // Convert to WordArray
            let encryptedData = CryptoJS.lib.WordArray.create(slice);
            // Hash the initial encrypted slice
            let xHashed = hashInitial(encryptedData);
            // Hash the hash
            let x2Hashed = CryptoJS.SHA256(xHashed);
            res(x2Hashed);
        } catch (error) {
            rej("Failed to get file identifier. Error: " + error);
        }
    }

    return new Promise((res, rej) => {
        onData(res, rej);   
    })
}

async function readSliceAsText(file, start, size) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        const slice = file.slice(start, start + size);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = reject;

        // Read the slice as text since it's already in Utf8
        fileReader.readAsText(slice);
    });
}

  /**
 * Reads slice of user's uploaded file.
 * @param {File} file - file containing data to be read
 * @param {int} start - starting position of slice to be read
 * @param {int} size - size of slice to be read
 */
  async function readSlice(file, start, size) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const slice = file.slice(start, start + size);
  
      fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(slice);
    });
  }

/**
 * Converts an array of 8 bit data into a CryptoJS WordArray
 * @param {Array} bytes - Any type of array containing 8 bit numerical data
 * @returns {WordArray} - CryptoJS word array, consisting of 32-bit words
 */
function bytesToWordArray(bytes) {
    var words = [];
    for (var i = 0; i < bytes.length; ++i) {
        var j = 24 - (i % 4) * 8;
        words[i >>> 2] |= bytes[i] << j;
    }
    return CryptoJS.lib.WordArray.create(words, bytes.length);
}

/**
 * Converts a CryptoJS WordArray into an 8 bit array
 * @param {WordArray} wa - 8 bit data encoded into a CryptoJS word array (32 bit words)
 * @returns {Uint8Array} - 8 bit array containing the hex data
 */
 function wordArrayToBytes(wa) {
    let bytes = [];
    for (var i = 0; i < wa.sigBytes; ++i) {
        var j = 24 - (i % 4) * 8;
        bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
    }
    return new Uint8Array(bytes);
}

/**
 * XORs two WordArrays, clamping the length to equal the smaller WordArray.
 * @param {WordArray} a - First WordArray to XOR
 * @param {WordArray} b - Second WordArray to XOR
 * @returns {WordArray} - XORed WordArray
 */
function performXOR(a, b) {
    let XORSize = Math.min(a.words.length, b.words.length);
    let c = [];
    for (let i = 0; i < XORSize; ++i) {
        c.push(a.words[i] ^ b.words[i]);
    }
    return CryptoJS.lib.WordArray.create(c, Math.min(a.sigBytes, b.sigBytes));
}

export async function oldDecrypt1(file, encryptedKey, initialValue, setDownloadProgress) {
    const FILENAME = file.name;
    
    async function onData(res, rej, encryptedKey, initialValue) {
        let xHashed;
        let initDec;
        let xOrKey = JSON.parse(encryptedKey);
        let iv = JSON.parse(initialValue);
        try {
            // Get the raw file data as bytes
            let slice = await readSlice(file, 0, SLICE_SIZE);
            let initWA = CryptoJS.lib.WordArray.create(slice);

            // Get the file identifier, encrypted key, and initial value vector
            xHashed = hashInitial(initWA);
        } catch {
            rej("Failed to get file identifier.");
        }
        
        // XOR the key with the file identifier to get the original key back
        let actualKey = performXOR(xHashed, xOrKey);

        let aesDec = CryptoJS.algo.AES.createDecryptor(actualKey, {
            mode: CryptoJS.mode.CFB,
            iv: iv
        });

        let start = 0;
        let decryptedBlobParts = [];
        
        while (start < file.size) {
            let slice = await readSlice(file, start, SLICE_SIZE);
            let wordArrayInput = CryptoJS.lib.WordArray.create(slice);
            let decryptedFileData = aesDec.process(wordArrayInput);

            if (start === 0) {
                initDec = decryptedFileData;
            }
            
            decryptedBlobParts.push(new Blob([wordArrayToBytes(decryptedFileData)]));
            start += SLICE_SIZE;
            setDownloadProgress({downloadProgress: Math.round((start / file.size) * 100)});
        }

        decryptedBlobParts.push(new Blob([wordArrayToBytes(aesDec.finalize())]));
        let finalDecryptedBlob = new Blob(decryptedBlobParts, { type: "application/octet-stream" });

        oldDownloadFile1(finalDecryptedBlob, FILENAME.slice(0, FILENAME.length - 3) + ".zip");   

        let initHashedCheck = hashInitial(initDec);

        let x2Hashed = CryptoJS.SHA256(xHashed);

        res({
            init: x2Hashed,
            proof: initHashedCheck
        });
    }

    return new Promise((res, rej) => {
        onData(res, rej, encryptedKey, initialValue); 
    })
  }

  
  export function oldDownloadFile1(blob, filename) {
  
    // Give the blob a URL
    let url = window.URL.createObjectURL(blob);
  
    // Create a click element, attach the blob, and download the file through a click
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = filename;
    a.click();
  
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  
/**
 * Gets a unique file identifier from the encrypted file
 * @param {WordArray} encryptedDataWA - Encrypted file data WordArray
 * @returns {WordArray} - Returns first 32 bytes of the encrypted file, hashed with SHA256
 */
function hashInitial(encryptedDataWA) {
    let encryptedWords = encryptedDataWA.words.slice(0, BYTES_TO_READ >>> 2);
    while (encryptedWords.length < BYTES_TO_READ >>> 2) {
        encryptedWords.push(0);
    }
    let x = CryptoJS.lib.WordArray.create(encryptedWords, BYTES_TO_READ);
    let xHashed = CryptoJS.SHA256(x)
    return xHashed;
}

/**
 * Decrypts the file and downloads the data
 * @param {File} file - File to decrypt
 * @param {WordArray} encryptedKey - Randomly generated 32 bit key XORed with the file identifier
 * @param {WordArray} initialValue - Randomly generated 16 byte number from the database
 * @param {FileSystemFileHandle} handle - handle for writing decrypted file
 * @param {Function} setDP - function handle to update download progress bar
 * @returns 
 */
export async function decrypt1(file, encryptedKey, initialValue, handle, setDownloadProgress) {
    const FILENAME = file.name;
    
    async function onData(res, rej, encryptedKey, initialValue) {
        let xHashed;
        let initDec;
        let xOrKey = JSON.parse(encryptedKey);
        let iv = JSON.parse(initialValue);
        try {
            // Get the raw file data as bytes
            let slice = await readSlice(file, 0, SLICE_SIZE);
            let initWA = CryptoJS.lib.WordArray.create(slice);

            // Get the file identifier, encrypted key, and initial value vector
            xHashed = hashInitial(initWA);
        } catch {
            rej("Failed to get file identifier.");
        }
        
        // XOR the key with the file identifier to get the original key back
        let actualKey = performXOR(xHashed, xOrKey);

        let aesDec = CryptoJS.algo.AES.createDecryptor(actualKey, {
            mode: CryptoJS.mode.CFB,
            iv: iv
        });

        let start = 0;
        
        const filestream = await handle.createWritable();
        const writer = await filestream.getWriter();
        
        while (start < file.size) {
            let slice = await readSlice(file, start, SLICE_SIZE);
            let wordArrayInput = CryptoJS.lib.WordArray.create(slice);
            let decryptedFileData = aesDec.process(wordArrayInput);
            downloadFile1(decryptedFileData, 'placeholder', writer);
            if (start === 0) {
                initDec = decryptedFileData;
            }
            start += SLICE_SIZE;
            setDownloadProgress({downloadProgress: Math.round((start / file.size) * 100)});
        }

        downloadFile1(aesDec.finalize(), FILENAME.slice(0, FILENAME.length-4), writer);
        writer.close();         

        let initHashedCheck = hashInitial(initDec);

        let x2Hashed = CryptoJS.SHA256(xHashed);

        res({
            init: x2Hashed,
            proof: initHashedCheck
        });
    }

    return new Promise((res, rej) => {
        onData(res, rej, encryptedKey, initialValue); 
    })
}

/**
 * Saves given data to file. Converts it to raw binary if necessary.
 * @param {WordArray} data - WordArray containing data to be downloaded
 * @param {string} filename - Desired name for save file (currently unused)
 * @param {WritableStreamDefaultWriter} writer - writer used to write encrypted data to file
 */
export async function downloadFile1(data, filename, writer) {
    if( !("showSaveFilePicker" in window) ) {
        document.body.textContent = "Unsupported browser... Please try with latest Chrome browser.";
    }
    writer.write(wordArrayToBytes(data));
}