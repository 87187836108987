import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import "./LoginMD.css";
import LoadingButton from "./LoadingButton";
import FeedbackAlert from "./FeedbackAlert";
import axios from "axios";
import {SERVER_URL} from "../assets/js/constants";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCircleInfo, faEye, faEyeSlash,} from "@fortawesome/free-solid-svg-icons";
import {getPageImage} from "./App";
import ComponentCard from "./ComponentCard";
import PasswordInput from "./PasswordInput";

library.add(faEye, faEyeSlash, faCircleInfo);

export default function Login() {
    const {login} = useAuth();
    const [isReactivating, setIsReactivating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // setFeedback({
            // loading: true,
            // message: "Logging in...",
            // variant: "info",
            // });
            setLoading(true);
            const errorCode = await login(
                e.target.email.value,
                e.target.password.value
            );
            if (errorCode) {
                // console.log("ecode", errorCode);
                if (errorCode === "requires_activation") {
                    setLoading(false);
                    setFeedback({
                        variant: "secondary",
                        message: (
                            <>
                <span>
                  Your account was marked for deletion. By logging back in, your
                  account will not be deleted.
                </span>
                                <LoadingButton
                                    className="w-100 mt-2"
                                    variant="primary"
                                    loading={isReactivating}
                                    onClick={() => {
                                        setIsReactivating(true);
                                        axios.post(`${SERVER_URL}cancelDeletion`, {
                                                userEmail: e.target.email.value,
                                            })
                                            .then((res) => {
                                                setFeedback({
                                                    message: "Account reactivated! Try logging in again.",
                                                    variant: "success",
                                                });
                                            })
                                            .catch((e) => {
                                                setFeedback({
                                                    message: "Error occurred while reactivating.",
                                                    variant: "danger",
                                                });
                                            })
                                            .finally(() => {
                                                setIsReactivating(false);
                                            });
                                    }}
                                >
                                    Reactivate my account
                                </LoadingButton>
                            </>
                        ),
                    });
                    return;
                } else {
                    // console.log(errorCode);
                    setLoading(false);
                    setFeedback({
                        message: handleLoginError(errorCode),
                        variant: "danger",
                    });
                    if (errorCode === "auth/too-many-requests") {
                        setDisabled(true);
                    }
                    return;
                }
            }
            if (e.target.email.value === "maintenance@cynorix.com") {
                navigate("/maintenance-dashboard");
                window.location.reload(true);
                return;
            }

            // no error
            setFeedback(null);
            navigate("/encrypt");
        } catch {
            setFeedback({
                message: "Failed to log in",
                variant: "danger",
            });
        }
        setLoading(false);
    };

    getPageImage();

    const helpContent = (
        <>
            <p className="mb-1">
                If you already have an account, you can use this page to log back in by
                filling in your email address and password, and clicking <b>Log In</b>.
            </p>
            <p className="mb-1">
                If you forget your password, you can click the{" "}
                <em>
                    <u>Forgot Password</u>
                </em>{" "}
                link underneath the <b>Log In</b> button. On that page, you can begin
                recovery of your account by providing the email address you use to Sign
                In to this application.
            </p>
        </>
    );

    return (
        <ComponentCard title={"Sign In"} helpContent={helpContent}>
            <Form onSubmit={handleSubmit}>
                <fieldset disabled={loading}>
                    <Form.Group className="mb-3 row" controlId="user email">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "8ch"}}>
                                Email
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row" controlId="user password">
                        <Col xs={12} sm={"auto"}>
                            <Form.Label column style={{width: "8ch"}}>
                                Password
                            </Form.Label>
                        </Col>
                        <Col>
                            <PasswordInput
                                name="password"
                                placeholder="Password"
                                required="required"
                            />

                            {/*  <Form.Text className="text-muted float-start">
              <Link to="/forgot-password">Forgot Password</Link>
            </Form.Text> */}
                        </Col>
                    </Form.Group>
                </fieldset>
                <LoadingButton
                    className="w-100 mb-1"
                    type="submit"
                    disabled={disabled}
                    loading={loading}
                >
                    Log In
                </LoadingButton>
                <div className="w-100 d-flex justify-content-start pe-2">
                    <Link to="/forgot-password" className="small text-secondary">
                        Forgot Password
                    </Link>
                </div>
            </Form>
            <FeedbackAlert feedback={feedback} className="mt-2"/>
            <hr/>
            <div style={{textAlign: "center"}}>
                Don't have an account? <Link to="/signup">Sign Up</Link>
            </div>
        </ComponentCard>
    );
}

const handleLoginError = (errorCode) => {
    switch (errorCode) {
        case "auth/invalid-email":
            return "This email is invalid.";
        case "auth/user-disabled":
            return "This user has been disabled.";
        case "auth/user-not-found":
            return "User not found.";
        case "auth/wrong-password":
        case "auth/internal-error":
            return "Wrong password.";
        case "auth/too-many-requests":
            return "You have submitted the wrong password too many times. Please proceed to forgot password to reset your password.";
        case "requires_activation":
            return "This account was set for deletion.";
        default:
            return "An unknown error has occurred.";
    }
};

export {handleLoginError};
