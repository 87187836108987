import React, { useRef, useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useAuth,postWithCredentials,getWithSecureCredentials } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { handleLoginError } from "./Login";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import LoadingButton from "./LoadingButton";
import FeedbackAlert from "./FeedbackAlert";
import ReactPasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./Signup.css";
import ComponentCard from "./ComponentCard";
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function VerifyCoupon() {
  const couponRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [isDiscounted, setIsDiscounted] = useState(false);

  useEffect(() => {
    // Fetch the flag from the server when the component mounts
    getWithSecureCredentials(SERVER_URL+'isUserDiscounted', {headers: {"Content-Type" : "application/json"}})
    .then((res) => {
        console.log(res)
        setIsDiscounted(res.data.couponFlag);
        if(res.data.couponFlag){
            setFeedback({
                loading: false,
                message: "You are already on a discounted tier.",
                variant: "danger",
              });
        }
        
    })
    .catch((err) => {
        console.error("Error fetching discounted flag:", err);
    });
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const promises = [];
    setFeedback({
      loading: true,
      message: "Verifying your credentials...",
      variant: "info",
    });


    //Code with try catch and return
    //console.log("Hello", couponRef.current.value);
    setCouponCode(couponRef.current.value)
    //console.log("couponcode",couponCode )

    postWithCredentials(SERVER_URL+'verifyCode',{couponCode: couponRef.current.value},{headers:{"Content-Type" : "application/json"}})
                    .then((res) => {
                        console.log(res);
                        setFeedback({
                            loading: true,
                            message: "Coupon verified",
                            variant: "success",
                          });
                        setTimeout(() => {
                            navigate("/upgrade-service");
                          }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        setFeedback({
                            message: "Coupon not verified",
                            variant: "danger",
                          });
                    })



    Promise.all(promises)
      .then(() => {
        setFeedback({
          loading: true,
          message: "Verifying Coupon...",
          variant: "info",
        });
        
      })
      .catch((err) => {
        setFeedback({
          message: "Failed to update account",
          variant: "danger",
        });
      })
      .finally(() => {
      });
  };

  const helpContent = (
    <>
      If you have a coupon for one of our subscriptions, enter it in the box
      and click <b>Verify</b>. Once we have verified your coupon, the subscription
      discounts will automatically become available on your account.
    </>
  );

  return (
    <ComponentCard title={"Verify Coupon"} helpContent={helpContent}>
      <Row>
        <Col>
          <FeedbackAlert feedback={feedback} />
          <Form onSubmit={handleSubmit} className="w-100 text-start">
            <Form.Group className="mt-2">
              <Row>
                <Col
                  xs={12}
                  lg={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                >
                  Coupon
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    id="coupon-field"
                    ref={couponRef}
                    required
                    placeholder="**********"
                  />
                </Col>
              </Row>
            </Form.Group>
           
            <Row>
              <Col>
                <LoadingButton
                  loading={feedback && feedback.loading}
                  className="mt-3 w-100"
                  id="verify-button"
                  type="submit"
                  disabled={isDiscounted} 
                >
                  Verify
                </LoadingButton>
              </Col>
            </Row>
            {/* <p href="/forgot-password">Forget password?</p> */}
          </Form>
        </Col>
      </Row>
    </ComponentCard>
  );
}



/* 
postWithCredentials(SERVER_URL+'requestSMSOTP',{userId: props.user.id},{headers:{"Content-Type" : "application/json"}})
                    .then((res) => {
                        console.log(res);
                        setShowSMSModal(true);
                    })

*/