import React, {Component} from 'react';

import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';

import './Dialog.css';

import PricingCard from './PricingCard';

/**
 * Handles frontend logic relevant to the profile dialog
 */
class ProfileDialog extends Component {
    /**
     * Class constructor
     * @param props - React props
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders visible components of profile page
     * @returns - JSX object
     */
    render() {
        return (
            <div className="dialog-container p-5"><Row><Col>
                <h1>Profile</h1>
                <p>Your encryption service subscription expires in __ days</p>
                <PricingCard/>
                {this.props.close &&
                    <Button className="m-2" variant="danger" onClick={this.props.close}>Close</Button>
                }
            </Col></Row></div>);
    }
}

export default ProfileDialog;
