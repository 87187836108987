import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Card, Col, Row} from "react-bootstrap";
import {auth} from "../firebase";
import {getUserFromEmail, getUserFromSecondIndex} from "../functions/getUser";
import BannerImage from "./BannerImage";
import LoadingButton from "./LoadingButton";
import {postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CancelSponsorship() {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userData, setUserData] = useState("");
    const [sponsorData, setSponsorData] = useState("");
    const [sponsorEmail, setSponsorEmail] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            setUserEmail(auth.currentUser.email);
            const tempUserData = await getUserFromEmail(auth.currentUser.email);
            setUserData(tempUserData);
            const tempSponsorData = await getUserFromSecondIndex(
                tempUserData.SponsorIndex
            );
            setSponsorData(tempSponsorData);
            setSponsorEmail(tempSponsorData.email);
        };
        getUsers();
    }, []);

    useEffect(() => {
        if (sponsorEmail) {
            setLoading(false);
        }
    }, [sponsorEmail]);

    const removeSponsorship = async () => {
        await postWithCredentials(SERVER_URL + "removeSponsorship", {
            employeeId: userData.user_id,
            sponsorId: sponsorData.user_id,
            employeeEmail: userEmail,
            sponsorEmail: sponsorEmail,
        })
            .then(() => {
                setSuccess("Sponsorship terminated.");
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setError("Failed Remove Sponsorship");
            });
    };

    return (
        <>
            <Card
                style={{
                    border: "none",
                    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                }}
            >
                <Card.Body>
                    <BannerImage url="/img/CYN_Cynorix.jpg"/>
                    <h4 className="mt-3">Cancel Sponsorship</h4>
                    <hr className="mt-0"/>
                    {!loading && (
                        <Row>
                            <Col>
                                <Card.Text className="ml-2">
                                    {"You are currently being sponsored by "}{" "}
                                    <b>{sponsorEmail}</b>{" "}
                                    {". Click the button below to cancel your sponsorship."}
                                </Card.Text>
                                {error && (
                                    <Alert
                                        variant="danger"
                                        className="d-flex align-items-center p-2"
                                    >
                                        <span className="me-auto">{error}</span>
                                    </Alert>
                                )}
                                {success && <Alert variant="success">{success}</Alert>}
                                <LoadingButton
                                    style={{
                                        marginRight: "10px",
                                        width: "100%",
                                    }}
                                    className="w-100 mb-1"
                                    onClick={() => {
                                        removeSponsorship();
                                    }}
                                >
                                    Cancel Sponsorship
                                </LoadingButton>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
