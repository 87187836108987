import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDVbiSQRQZLVBqyxE5wAAZKuUQDAN1Eqeo",
    authDomain: "payment-sys-prod.firebaseapp.com",
    databaseURL: "https://payment-sys-prod-default-rtdb.firebaseio.com",
    projectId: "payment-sys-prod",
    storageBucket: "payment-sys-prod.appspot.com",
    messagingSenderId: "135395542147",
    appId: "1:135395542147:web:d03c34ce3a43c48a2547b6",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
