import {
    DECRYPTION_BAD_PROOF,
    DECRYPTION_NEEDS_VERIFICATION,
    DECRYPTION_NO_USES,
    DECRYPTION_NO_VERIFICATION,
    DECRYPTION_NOT_FOUND,
    DECRYPTION_PHONE_NOT_RECOGNIZED,
    DECRYPTION_SUCCESS,
    ENCRYPTION_SUCCESS,
    SERVER_2FA_APPROVED,
    SERVER_2FA_CANCELED,
    SERVER_2FA_NEED_VERIFICATION,
    SERVER_2FA_NOT_ALLOWED,
    SERVER_2FA_NOT_FOUND,
    SERVER_2FA_NOT_NEEDED,
    SERVER_2FA_PENDING,
    SERVER_2FA_STATUS_FAIL,
    SERVER_DECRYPT_BAD_PROOF,
    SERVER_DECRYPT_FAILURE,
    SERVER_DECRYPT_NO_USES,
    SERVER_DECRYPT_NOT_FOUND,
    SERVER_ENCRYPTION_SUCCESS,
    SERVER_SUBSCRIPTION_FOUND,
    SERVER_SUBSCRIPTION_NOT_FOUND,
    SERVER_URL,
    SERVER_USER_FOUND,
    SERVER_USER_NOT_FOUND,
    TWO_FACTOR_APPROVED,
    TWO_FACTOR_ATTEMPT_FAIL,
    TWO_FACTOR_CANCEL_SUCCESS,
    TWO_FACTOR_CANCELED,
    TWO_FACTOR_MAX_TRIES,
    TWO_FACTOR_TRIES_EXCEEDED,
    USER_DATA_FOUND,
    USER_DATA_NOT_FOUND,
    USER_DATA_SUBSCRIPTION_FOUND,
    USER_DATA_SUBSCRIPTION_NOT_FOUND
} from '../assets/js/constants';
import {handleConnectionError} from './notifications';
import {postWithCredentials} from "../contexts/AuthContext";

// Holds number of times an unsuccessful 2FA has occurred
var bad2FACount = 0;

/**
 * Communicates with the server to insert encryption data about the current file into the Mongo database
 * @param {Object} data - An object containing encryption information about the file, including the key, number of uses, and expiry date
 * @returns {Object} - Returns an object with a message key indicating a successful database insertion. Throws an error otherwise.
 */
export function sendEncryptionData(data) {
    return postWithCredentials(SERVER_URL + 'encrypt', {}, {
        params: {
            init: data.init,
            key: data.key,
            proof: data.proof,
            iv: data.iv,
            uses: data.uses,
            time: data.time,
            email: data.email,
            twoFactorContacts: data.twoFactorContacts,
            type2FA: data.type2FA,
            filename: data.filename,
            isNotifEnabled: data.isNotifEnabled
        }
    }).then(res => {
        if (res.data.status === SERVER_ENCRYPTION_SUCCESS)
            return {message: ENCRYPTION_SUCCESS};
    }).catch(err => handleConnectionError(err));
}

/**
 * Communicates with Firebase Cloud Function server to get a reference to the file on the database,and initiates a 2FA request if needed
 * @param {string} id - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @param {string} number - The phone number with which to initiate a 2FA request if it is required
 * @returns {Object} - Information about the file stored on the database. Specifically its key and initial value for AES encryption.
 */
export function getDecryptionData(id, contact) {
    bad2FACount = 0;
    return postWithCredentials(SERVER_URL + 'decrypt', {}, {
        params: {
            init: id,
            //phoneNumber: number
            contact: contact
        }
    })
        .then(res => {
            if (res.data.status === SERVER_DECRYPT_FAILURE && res.data.reason === SERVER_DECRYPT_NOT_FOUND) {
                return {message: DECRYPTION_NOT_FOUND};
            } else if (res.data.status === SERVER_2FA_NEED_VERIFICATION && res.data.needToVerify && res.data.allowedToDecrypt) {
                return {
                    message: DECRYPTION_NEEDS_VERIFICATION,
                    sid: res.data.verificationSID
                };
            } else if (res.data.status === SERVER_2FA_NOT_ALLOWED && res.data.needToVerify && !res.data.allowedToDecrypt) {
                return {message: DECRYPTION_PHONE_NOT_RECOGNIZED};
            } else if (res.data.status === SERVER_2FA_NOT_NEEDED) {
                return {
                    message: DECRYPTION_NO_VERIFICATION,
                    key: res.data.key,
                    iv: res.data.iv
                };
            }
        }).catch(err => handleConnectionError(err));
}

/**
 * Sends a message to the server indicating the file was decrypted properly. Server sends email notification and checks if decryption was done correctly.
 * @param {string} init - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @param {string} proof - The first 32 bytes of the decrypted file, hashed with SHA256, converted to WordArray. Proves that the file was decrypted properly
 * @param {string} number - Decrypter's phone number, to send to encrypter within the email notification
 * @returns {Object} - Returns status of decryption and reason for failure if there is one. Throws an error if connection to server fails.
 */
export function sendDecryptSuccess(init, proof, contact) {
    return postWithCredentials(SERVER_URL + 'decryptsuccess', {}, {
        params: {
            init: init,
            proof: proof,
            contact: contact
        }
    }).then(res => {
        if (res.data.status === SERVER_DECRYPT_FAILURE) {
            if (res.data.reason === SERVER_DECRYPT_NOT_FOUND)
                return {message: DECRYPTION_NOT_FOUND};
            else if (res.data.reason === SERVER_DECRYPT_NO_USES)
                return {message: DECRYPTION_NO_USES};
            else if (res.data.reason === SERVER_DECRYPT_BAD_PROOF)
                return {message: DECRYPTION_BAD_PROOF};
        } else if (res.data.status === SERVER_ENCRYPTION_SUCCESS) {
            return {message: DECRYPTION_SUCCESS};
        }
    }).catch(err => handleConnectionError(err));
}

/**
 * Checks if the user's provided code matches the one sent to them via 2FA
 * @param {string} code - The code which the user received on their phone
 * @param {string} number - The phone number to which the 2FA request was sent
 * @param {string} id - The first 32 bytes of the encrypted file, hashed with SHA256, converted to WordArray. Unique identifier for the file on the database.
 * @returns {Object} - Returns a message indicating whether the request was approved, and can additionally return the file's encrypted key and iv. Throws an error if connection fails.
 */
export function checkCode(code, contact, verificationType, id) {
    return postWithCredentials(SERVER_URL + "verify", {}, {
        params: {
            code: code,
            //phoneNumber: number,
            contact: contact,
            type: verificationType,
            init: id
        }
    }).then(res => {
        if (res.data.status === SERVER_2FA_APPROVED) {
            bad2FACount = 0;
            return {
                message: TWO_FACTOR_APPROVED,
                key: res.data.key,
                iv: res.data.iv
            };
        } else if (res.data.status === SERVER_2FA_PENDING) {
            bad2FACount++;
            if (bad2FACount > TWO_FACTOR_MAX_TRIES) {
                bad2FACount = 0;
                return {message: TWO_FACTOR_TRIES_EXCEEDED};
            } else {
                return {message: TWO_FACTOR_ATTEMPT_FAIL};
            }
        } else if (res.data.status === SERVER_2FA_CANCELED) {
            return {message: TWO_FACTOR_CANCELED};
        } else if (res.data.status === SERVER_2FA_STATUS_FAIL && res.data.reason === SERVER_2FA_NOT_FOUND) {
            return {message: DECRYPTION_NOT_FOUND}
        }
    }).catch(err => handleConnectionError(err));
}

/**
 * Cancels the user's 2FA request
 * @param {string} sid - ID of the current 2FA request
 * @returns {Object} - Message indicating successful cancelation of 2FA request. Throws an error if connection to server fails.
 */
export function cancel2FARequest(sid, verificationType) {
    return postWithCredentials(SERVER_URL + "cancel", {}, {
        params: {
            sid: sid,
            verificationType: verificationType
        }
    }).then(res => {
        return {message: TWO_FACTOR_CANCEL_SUCCESS}
    }).catch(err => handleConnectionError(err));
}

/**
 * Finds the given user in the database
 *
 * @param {string} id - ID of user in the database
 * @returns {Object} - Message indicating whether user was found
 */
export function findUser(id) {
    return postWithCredentials(SERVER_URL + "getUser", {}, {
        params: {
            id: id
        }
    }).then(res => {
        if (res.data.status === SERVER_USER_FOUND) {
            return {message: USER_DATA_FOUND, user: res.data.userData};
        } else if (res.data.status === SERVER_USER_NOT_FOUND) {
            return {message: USER_DATA_NOT_FOUND};
        }
    }).catch(err => handleConnectionError(err));
}

/**
 * Contacts payment service to see if the provided subscription is still valid
 * @param {string} id - ID of the subscription
 * @returns {Object} - Message indicating if subscription was found, and gives date of expiry.
 */
export function checkSubscriptionValidity(id) {
    return postWithCredentials(SERVER_URL + "subscriptionCheck", {}, {
        params: {
            id: id
        }
    }).then(res => {
        if (res.data.status === SERVER_SUBSCRIPTION_FOUND) {
            return {
                message: USER_DATA_SUBSCRIPTION_FOUND,
                subscription: res.data.subscription,
                stillValid: res.data.stillValid
            };
        } else if (res.data.status === SERVER_SUBSCRIPTION_NOT_FOUND) {
            return {message: USER_DATA_SUBSCRIPTION_NOT_FOUND};
        }
    }).catch(err => handleConnectionError(err));
}
