import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import BannerImage from "./BannerImage";
import {useNavigate, useParams} from "react-router-dom";
import {auth} from "../firebase";
import "./App.css";
import {getUserFromEmail} from "../functions/getUser";
import {postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function EditClass() {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [serviceClassName, setServiceClassName] = useState("");
    const [billingPeriod, setBillingPeriod] = useState("1");
    const [basicRate, setBasicRate] = useState(0);
    const [currency, setCurrency] = useState("CAD");
    const [mapOfAttributes, setMapOfAttributes] = useState(new Map());
    const [attributesData, setAttributesData] = useState({});
    const [hasAccess, setHasAccess] = useState(false);
    const [fileattributes, setFileAttributes] = useState({
        name: "Files Encrypted",
        limit: "",
        chunk: 0,
        rate: "",
    });
    const {classId} = useParams();
    const navigate = useNavigate();
    const [origLimit, setOrigLimit] = useState("");
    const [origChunk, setOrigChunk] = useState("");
    const [origRate, setOrigRate] = useState("");

    ////////
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    ////////


    const params = useParams();
    useEffect(() => {
        const exampleAttributes = [
            "Files Encrypted",
            "Messages Sent",
            "Messages Verified",
            "Emails Sent",
            "Emails Verified",
        ];

        // check access before proceeding
        const userEmail = auth.currentUser.email;
        if (userEmail !== "maintenance@cynorix.com") return;
        setHasAccess(true);

        (async () => {
            try {
                const {classId} = params;

                console.log("classID", classId);
                const userEmail = auth.currentUser.email;
                const userData = await getUserFromEmail(userEmail);

                // load and render existed data in the EditClass.js
                const classData = userData.products[classId];
                setAttributesData(classData.attributes);
                setServiceClassName(classData.subscription_name);
                setBillingPeriod(classData.billing_period);
                setBasicRate(classData.basic_rate);
                setCurrency(classData.currency);
                // load list of name of attributes
                const attributes = [];
                for (const attribute in classData.attributes) {
                    attributes.push(classData.attributes[attribute].name);
                }

                attributes.forEach((item) => {
                    if (exampleAttributes.includes(item)) {
                        let newMap = new Map(mapOfAttributes)
                        newMap.set(item, 1);
                        setMapOfAttributes(newMap);
                    }
                });
            } catch (e) {
                console.log(e);
                alert("Failed to render the page");
            }
        })();
    }, [params]);

    /*  const handleFormCheckOnChange = (checked, exampleAttribute) => {
        mapOfAttributes.set(exampleAttribute, checked ? 1 : 0);
        setMapOfAttributes(mapOfAttributes);

        // listOfAttributes are the attributes that are checked
        setListOfAttributes(
          Array.from(mapOfAttributes, ([key, value]) => ({
            key,
            value,
          }))
            .map(({ key, value }) => ({ [key]: value }))
            .filter((element) => {
              return Object.values(element)[0] === 1;
            })
            .map((attribute) => {
              return Object.keys(attribute)[0];
            })
        );
      };*/

    // const handleSetAttributes = (val, attribute, prop) => {
    // 	let tempAttributes = finalAttributes;
    // 	const attribute_key = attribute.toLowerCase().split(' ').join('_');
    // 	if (!finalAttributes[attribute_key]) {
    // 		tempAttributes[attribute_key] = { name: attribute };
    // 	}
    // 	tempAttributes[attribute_key][prop] = val;
    // 	console.log(finalAttributes);
    // 	// setFinalAttributes(tempAttributes);
    // };

    //Handle Create Class
    const handleCreateClass = async (e) => {
        e.preventDefault();

        // validate chunk cost at least a dollar
        if (fileattributes.chunk * fileattributes.rate < 100) {
            alert("Chunk cost to low! increase chunk count or rate.");
            return;
        }

        const newClass = {
            subscription_name: serviceClassName,
            billing_period: billingPeriod,
            basic_rate: basicRate,
            currency: currency,
            attributes: {files_encrypted: fileattributes},
        };
        // console.log(newClass);
        try {
            setError("");
            await editClass(newClass);
            // no error
            setSuccess("Edited class successfully");
            setTimeout(() => {
                setSuccess("");
                navigate("/edit-service-class");
            }, 3000);
        } catch {
            setError("Failed to edit class");
            setTimeout(() => {
                setError("");
            }, 1500);
        }
    };

    const handleRemove = async (e) => {
        e.preventDefault();
        try {
            setError("");
            await removeClass();
            // no error
            setSuccess("Removed class successfully");
            setTimeout(() => {
                setSuccess("");
                navigate("/edit-service-class");
            }, 1500);
        } catch {
            setError("Failed to remove class");
            setTimeout(() => {
                setError("");
            }, 1500);
        }
    };

    const editClass = async (newClass) => {
        const userId = auth.currentUser.uid;
        // console.log(newClass);
        await postWithCredentials(SERVER_URL + "editClass", {
            class: newClass,
            userId: userId,
            key: classId,
        })
            .then(() => {
                // console.log('Updated Service Class');
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    const removeClass = async () => {
        const userId = auth.currentUser.uid;
        await postWithCredentials(SERVER_URL + "removeClass", {
            userId: userId,
            key: classId,
        })
            .then((response) => {
                // console.log(response);
            })
            .catch((err) => {
                // console.log(err);
                throw err;
            });
    };

    useEffect(() => {
        // console.log(attributesData["files_encrypted"]);
        if (attributesData["files_encrypted"]) {
            setFileAttributes({
                name: "Files Encrypted",
                limit: attributesData["files_encrypted"].limit,
                chunk: attributesData["files_encrypted"].chunk,
                rate: attributesData["files_encrypted"].rate,
            });
            setOrigLimit(attributesData["files_encrypted"].limit);
            setOrigChunk(attributesData["files_encrypted"].chunk);
            setOrigRate(attributesData["files_encrypted"].rate);
        }
    }, [attributesData]);

    return (
        <>
            {hasAccess && (
                <>
                    <Card
                        style={{
                            border: "none",
                            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <Card.Body>
                            <BannerImage url="/img/CYN_Cynorix.jpg"/>
                            <h4 className="mt-3">
                                <b>Edit Class</b>
                            </h4>
                            <hr className="mt-0"/>
                            <Row>
                                <Col>
                                    <Form onSubmit={handleCreateClass}>
                                        <Form.Group id="subscription_name" className="mb-3">
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    lg={2}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                    className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                >
                                                    Subscription Name
                                                </Col>
                                                <Col xs={12} lg={10}>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={serviceClassName}
                                                        onChange={(event) =>
                                                            setServiceClassName(event.target.value)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group id="billing_period" className="mb-3">
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    lg={2}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                    className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                >
                                                    Billing Period
                                                </Col>
                                                <Col xs={12} lg={10}>
                                                    <Form.Select
                                                        value={billingPeriod}
                                                        onChange={(event) =>
                                                            setBillingPeriod(event.target.value)
                                                        }
                                                    >
                                                        <option value={"1"}>1 Month</option>
                                                        <option value={"12"}>1 Year</option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group id="basic_rate" className="mb-3">
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    lg={2}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                    className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                >
                                                    Basic Rate
                                                </Col>
                                                <Col xs={12} lg={5}>
                                                    <Form.Control
                                                        type="number"
                                                        min={0}
                                                        value={basicRate}
                                                        onChange={(event) =>
                                                            setBasicRate(event.target.value)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col xs={12} lg={5}>
                                                    <Form.Select
                                                        value={currency}
                                                        onChange={(event) => {
                                                            setCurrency(event.target.value);
                                                        }}
                                                    >
                                                        <option value={"CAD"}>CAD</option>
                                                        <option value={"USD"}>USD</option>
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group id="file-attribute">
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    lg={2}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 0,
                                                    }}
                                                    className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                                                >
                                                    File attributes
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Control
                                                        min={0}
                                                        type="number"
                                                        required
                                                        defaultValue={origLimit}
                                                        placeholder="Encryption Limit"
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            fileattributes.limit = event.target.value;
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Form.Control
                                                        min={0}
                                                        placeholder="# of Chunks"
                                                        type="number"
                                                        required
                                                        defaultValue={origChunk}
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            fileattributes.chunk = parseInt(
                                                                event.target.value
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Form.Control
                                                        min={0}
                                                        type="number"
                                                        placeholder="Price per chunk"
                                                        required
                                                        defaultValue={origRate}
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            fileattributes.rate = event.target.value;
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group className="mb-3 text-start">
                                            {/* <Form.Label column='lg'>
												Select from example attributes:
											</Form.Label>
<Row>
											{exampleAttributes.map(
												(exampleAttribute, index) => {
													const checked =
														mapOfAttributes.get(
															exampleAttribute
														) === 1
															? true
															: false;

													return (
                                                        <Col xs={6}>
														<Form.Check
															type='checkbox'
															id={index + 1}
															key={index + 1}
															label={
																exampleAttribute
															}
															disabled={confirmed}
															checked={checked}
															onChange={(event) =>
																handleFormCheckOnChange(
																	event.target
																		.checked,
																	exampleAttribute
																)
															}
														/>
                                                        </Col>
													);
												}
											)}
                                            </Row>

											<Button
												className='mt-3 mb-3 w-100'
                                                style={{
                                                    backgroundColor: "#800000",
                                                    color: "white",
                                                    border: "none",
                                                    marginRight: "10px",
                                                    width: "100%"
                                                  }}
												type='submit'
												onClick={() => {
													setConfirmed(true);
												}}
												disabled={
													listOfAttributes.length ===
														0 || confirmed
												}
											>
												Confirm Attributes
											</Button>

											<hr />

											{listOfAttributes.length !== 0 && (
												<FormGroup>
													<Form.Label column='lg'>
														Set limit, chunk, and
														rate for selected
														attributes:
													</Form.Label>
													<Row>
														<Col xs={6}>
															<Form.Label column>
																Attributes
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Limit
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Chunk
															</Form.Label>
														</Col>
														<Col xs={2}>
															<Form.Label column>
																Rate (Cents)
															</Form.Label>
														</Col>
													</Row>
												</FormGroup>
											)}

											<ListGroup className='mb-3'>
												{listOfAttributes.map(
													(attribute, index) => {
														const attribute_key =
															attribute
																.toLowerCase()
																.split(' ')
																.join('_');
														let limitVal = '';
														let chunkVal = '';
														let rateVal = '';

														if (
															attributesData[
																attribute_key
															]
														) {
															limitVal =
																attributesData[
																	attribute_key
																].limit;
															handleSetAttributes(
																limitVal,
																attribute,
																'limit'
															);
															chunkVal =
																attributesData[
																	attribute_key
																].chunk;
															handleSetAttributes(
																chunkVal,
																attribute,
																'chunk'
															);
															rateVal =
																attributesData[
																	attribute_key
																].rate;
															handleSetAttributes(
																rateVal,
																attribute,
																'rate'
															);
														}

														return (
															<ListGroup.Item
																key={index + 1}
																id={index + 1}
															>
																<Form.Group
																	id={
																		attribute
																	}
																>
																	<Row>
																		<Col
																			xs={
																				6
																			}
																		>
																			<Form.Label
																				column
																			>
																				{
																					attribute
																				}
																			</Form.Label>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				type='number'
																				min={
																					0
																				}
																				required
																				defaultValue={
																					limitVal
																				}
																				onChange={(
																					event
																				) =>
																					handleSetAttributes(
																						event
																							.target
																							.value,
																						attribute,
																						'limit'
																					)
																				}
																			/>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				type='number'
																				min={
																					0
																				}
																				required
																				defaultValue={
																					chunkVal
																				}
																				onChange={(
																					event
																				) =>{
																					handleSetAttributes(
																						parseInt(event
																							.target
																							.value),
																						attribute,
																						'chunk'
																					)}
																				}
																			/>
																		</Col>
																		<Col
																			xs={
																				2
																			}
																		>
																			<Form.Control
																				disabled={
																					!confirmed
																				}
																				type='number'
																				min={
																					0
																				}
																				required
																				defaultValue={
																					rateVal
																				}
																				onChange={(
																					event
																				) =>
																					handleSetAttributes(
																						event
																							.target
																							.value,
																						attribute,
																						'rate'
																					)
																				}
																			/>
																		</Col>
																	</Row>
																</Form.Group>
															</ListGroup.Item>
														);
													}
												)}
											</ListGroup> */}
                                            {success && <Alert variant="success">{success}</Alert>}
                                            {error && <Alert variant="danger">{error}</Alert>}

                                            <Button
                                                className="mt-3 w-100"
                                                onClick={handleShow}
                                                style={{
                                                    backgroundColor: "#800000",
                                                    color: "white",
                                                    border: "none",
                                                    marginRight: "10px",
                                                    width: "100%",
                                                }}
                                            >
                                                Remove Class
                                            </Button>

                                            <Button
                                                className="mt-3 w-100 "
                                                type="submit"
                                                style={{
                                                    backgroundColor: "#800000",
                                                    color: "white",
                                                    border: "none",
                                                    marginRight: "10px",
                                                    width: "100%",
                                                }}
                                            >
                                                Confirm Edit Class
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            )}

            {!hasAccess && <h4>Access denied.</h4>}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    If you want to remove this class, please click confirm button to
                    continue.
                </Modal.Body>
                {success && <Alert variant="success">{success}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#800000",
                            color: "white",
                            border: "none",
                        }}
                        onClick={handleRemove}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
