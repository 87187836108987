import React from "react";
import "./Faq.css";
import lock from "../assets/icons/svg/lock.svg";
import tool from "../assets/icons/svg/tool.svg";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import BannerImage from "./BannerImage";

const securityAccordionContents = [
    {
        header: "Does Cynorix have access to my file data?",
        body: `
      No. Encrypted files do not leave sender's computer unless through
      the sharing mechanism selected/used by the sender. 
      Cynorix neither has access to, nor the ability to user's encrypted/
      unencrypted files. Also, Cynorix neither has access to, nor 
      the ability to reconstruct, the encryption key.
    `,
    },
    {
        header: "Are payments secure?",
        body: `
      We work with top payment companies who prioritize your
      safety and security. All billing information is 
      stored on our payment processing partner.
    `,
    },
    {
        header: "Does my password get stored anywhere?",
        body: `No.
      Your password is hashed and inaccessible to Cynorix or third parties.
    `,
    },
    {
        header: "Do you offer 2-Factor Authentication?",
        body: `
      We offer strong 2-Factor Authentication for you to ensure your account is secure.
    `,
    },
];

const usageAccordionContents = [
    {
        header: "What are the requirements to use this tool?",
        body: `
      To start using the tool, you need a device with a browser
      and a mobile device (to register for 2-Factor Authentication).
    `,
    },
    {
        header: "Is there a free trial?",
        body: `
      Of course! We’re happy to offer a free plan to anyone 
      who wants to try our service.
    `,
    },
    {
        header: "What if I reach the limit? Can I still use the service?",
        body: `
      You may encrypt more files after reaching your service limit 
      by buying chunks in My Subscription -> Buy Chunks. 
      This purchase will give you access to more encryptions.
    `,
    },
    {
        header: "When does my subscription update? Why can't I delete my account?",
        body: `
      Changes to your subscription and account will occur on a monthly
      basis. If you change or cancel your subscription or account, they will go
      through next month.
    `,
    },
];

export default function Faq(props) {
    function generateAccordionContents(contents) {
        return contents.map(({header, body}, index) => (
            <Accordion.Item eventKey={index} style={{background: "none"}}>
                <Accordion.Header>{header}</Accordion.Header>
                <Accordion.Body>{body}</Accordion.Body>
            </Accordion.Item>
        ));
    }

    return (
        <Card
            body
            className="pb-0"
            style={{
                border: "none",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
        >
            <BannerImage url="/img/CYN_How_small.jpg"/>
            <h4 className="mt-3">
                <b>Frequently Asked Questions</b>
            </h4>
            <hr className="mt-0"/>
            <Row>
                <Col xs={12} md={6}>
                    <Accordion alwaysOpen>
                        <Accordion.Item>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <img className="icon me-2" src={lock} alt="lock"/>
                                <h5 className="m-0 p-0">Security</h5>
                            </div>
                        </Accordion.Item>
                        {generateAccordionContents(securityAccordionContents)}
                    </Accordion>
                </Col>
                <Col xs={12} md={6}>
                    <Accordion alwaysOpen>
                        <Accordion.Item>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <img className="icon me-2" src={tool} alt="lock"/>
                                <h5 className="m-0 p-0">Tool Usage</h5>
                            </div>
                        </Accordion.Item>
                        {generateAccordionContents(usageAccordionContents)}
                    </Accordion>
                </Col>
            </Row>
            <hr/>
            <p class="text-center my-2 mb-1 note">
                If you have any other questions, <a href="/contact">Contact Us</a>
            </p>
        </Card>
    );
}

