import {useEffect, useState} from "react";
import {Alert, Spinner} from "react-bootstrap";

export default function FeedbackAlert(props) {
    const [feedback, setFeedback] = useState(props.feedback);
    useEffect(() => {
        setFeedback(props.feedback);
    }, [props.feedback]);

    if (feedback === null) return null;

    return (
        <Alert
            variant={feedback.variant}
            className={props.className}
            style={props.style}
        >
            {feedback.loading === true ? (
                <>
                    <Spinner size="sm" animation="border"/>{" "}
                </>
            ) : null}
            {feedback.message}
        </Alert>
    );
}
