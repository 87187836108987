import React, {useEffect, useState} from "react";
import {PaymentElement, useElements, useStripe,} from "@stripe/react-stripe-js";
import LoadingButton from "./LoadingButton";
import FeedbackAlert from "./FeedbackAlert";

export default function CheckoutPage(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [feedback, setFeedback] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }
        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );
        if (!clientSecret) {
            return;
        }

        // Retrieve the Payment Intent
        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            // Inspect the PaymentIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            switch (paymentIntent.status) {
                case "succeeded":
                    setFeedback({
                        variant: "success",
                        message: "Success! Payment received.",
                    });
                    break;

                case "processing":
                    setFeedback({
                        loading: true,
                        variant: "info",
                        message:
                            "Payment processing. We'll update you when payment is received.",
                    });
                    break;

                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again

                    setFeedback({
                        variant: "danger",
                        message: "Payment failed. Please try another method.",
                    });
                    break;

                default:

                    setFeedback({
                        variant: "danger",
                        message: "Something went wrong.",
                    });
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_PAYMENT_URL, //The payment page was redirecting to wrong URL.
            },
            redirect: "if_required",
            // confirmParams: {
            //     return_url: process.env.REACT_APP_FRONTEND_URL,
            // },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)

            setFeedback({
                variant: "danger",
                message: result.error.message,
            });
        } else {
            setFeedback({
                loading: true,
                variant: "success",
                message: "Payment success. Redirecting...",
            });
            setTimeout(() => {
                window.location.assign("/service-usage-info");
            }, 3000);
        }
        setIsLoading(false);
    };

    return (
        <form className="pb-0" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" className="mb-3"/>
            <LoadingButton
                loading={isLoading}
                disabled={isLoading || !stripe || !elements}
                style={{width: "100%"}}
                type="submit"
            >
                Pay
            </LoadingButton>
            {/* Show any error or success messages */}
            <FeedbackAlert feedback={feedback} className="mt-2 mb-0"/>
        </form>
    );
}

