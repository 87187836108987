import {useEffect, useState} from "react";
import {Button, Spinner} from "react-bootstrap";

export default function LoadingButton(props) {
    const {loading, children, disabled, ...buttonProps} = props;
    const [loadingState, setLoadingState] = useState(loading);

    useEffect(() => {
        setLoadingState(props.loading);
    }, [props.loading]);

    return (
        <Button disabled={loading || disabled} {...buttonProps}>
            {loadingState ? <Spinner size="sm" animation="border"/> : null}{" "}
            {children}
        </Button>
    );
}
