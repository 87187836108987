import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, Card, Col, Form, ListGroup, Row, Spinner,} from "react-bootstrap";
import {auth} from "../firebase";
import {verifyCode} from "../functions/verify";
import LoadingButton from "./LoadingButton";
import ComponentCard from "./ComponentCard";
import {useNavigate} from "react-router-dom";
import {postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function OAuth() {
    // because this is a destructive step, ask for confirmation before
    // resetting the user's 2FA.
    const [confirmIntention, setConfirmIntention] = useState(false);
    const [gettingQRCode, setGettingQRCode] = useState(false);
    const [isVerifying2FA, setIsVerifying2FA] = useState(false);
    const [data, setData] = useState("");
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [haveSecret, setHaveSecret] = useState(false);

    const navigate = useNavigate();

    const renderQRCode = useCallback(async () => {
        setGettingQRCode(true);
        setError("");
        try {
            const userId = auth.currentUser.uid;
            const qrCode = await generateQRCode(userId);
            setData(qrCode);
        } catch (e) {
            // alert("Error Rendering Page. Please try refreshing the page.");
            setError("Error Rendering QR Code. Please try refreshing.");
            // console.log(e);
        }

        setGettingQRCode(false);
    }, [setData, setError, setGettingQRCode]);

    useEffect(() => {
        if (confirmIntention) {
            renderQRCode();
        }
    }, [confirmIntention, renderQRCode]);

    useEffect(() => {
        postWithCredentials(SERVER_URL + "checkSecret", {
            user_id: auth.currentUser.uid,
        }).then((res) => {
            // console.log(res.data.secret);
            setHaveSecret(res.data.secret);
        });
    }, []);


    const generateQRCode = async (id) => {
        return await postWithCredentials(SERVER_URL + "qrcode", {
            userId: id,
        })
            .then((response) => {
                // console.log("Posted QR code", response);
                if (response.data.data === {}) {
                    throw Error("No QR Code");
                }
                return response.data.data;
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    const verify2FA = async (e) => {
        e.preventDefault();
        setIsVerifying2FA(true);
        setError("");
        try {
            const userId = auth.currentUser.uid;
            // console.log(code);
            const verification = await verifyCode(code, userId);
            // console.log(verification);
            if (verification) {
                setSuccess("Successfully Set Up 2-Factor Authentication");
                setError("");
                setTimeout(() => {
                    if (auth.currentUser.email === "maintenance@cynorix.com") {
                        navigate("/maintenance-dashboard");
                    } else {
                        navigate("/dashboard");
                    }
                }, 1000);
            } else {
                setError("The code is incorrect");
            }
        } catch (err) {
            console.log(err);
            setError("Error verifying the code, please try again");
            window.location.reload();
        }
        setIsVerifying2FA(false);
    };

    const helpContent = (
        <>
            {" "}
            <p className="mb-1">
                <b>Warning</b>: Once you begin the 2FA process, your old 2FA is erased.
                Thus, if you decide to exit this process midway, you will invalidate
                your previous 2FA and must re-register the 2FA QR Code again.
            </p>
            <p>
                To minimize accidental clicks, this page requires you to confirm your
                intention before it begins the process of registering your for 2FA. To
                exit this page, you can click Go Back in the sidebar. To continue, click
                Yes on the page.
            </p>
            <hr/>
            <p className="mb-1">
                The next page details a list of instructions for the user to register
                2FA. Once registered, you will then see a six-digit code in the
                authenticator app on your smartphone. Enter that six-digit code into the
                web application and click <b>Verify Code</b> to finish 2FA registration.{" "}
            </p>
            <p className="mb-1">
                <em>
                    Note: This code is only valid for an interval of time, so it is
                    important to enter the code before it expires. If the code you entered
                    is incorrect, you can attempt the code again, or you can click the
                    Refresh QR Code button,{" "}
                    <b>
                        after which you will need to restart the registration procedure
                        again.
                    </b>
                </em>
            </p>
        </>
    );

    if (!confirmIntention)
        return (
            <ComponentCard
                title="Set Up 2-Factor Authentication using Google Authenticator App"
                helpContent={helpContent}
            >
                {haveSecret ? (
                    <span>
            You have 2-Factor-Authentication already, do you want to reset it?
            Click Yes button to continue or No button to go back.
            <br></br>
            <strong>
              Please note that once you confirm your decision by clicking 'Yes',
              your current 2FA will be terminated. To continue using 2FA related
              services, you will need to complete the process of registering for
              a new 2FA.
            </strong>{" "}
          </span>
                ) : (
                    <span>
            If you want to set 2-Factor-Authentication, please click Yes button
            to continue.
          </span>
                )}
                <Row className="gx-2">
                    <Col>
                        <Button
                            className="w-100 mt-3"
                            onClick={() => {
                                navigate("/dashboard");
                            }}
                            variant="secondary"
                        >
                            Back to Dashboard
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="w-100 mt-3"
                            onClick={() => {
                                setConfirmIntention(true);
                            }}
                        >
                            Yes
                        </Button>
                    </Col>
                </Row>
            </ComponentCard>
        );

    return (
        <>
            <ComponentCard
                title="Set Up 2-Factor Authentication using Google Authenticator App"
                helpContent={helpContent}
            >
                <Row>
                    <Col>
                        <Card.Text className="ml-2">
                            To set up the 2-Factor Authentication, please follow the
                            instructions below:
                        </Card.Text>

                        <ListGroup as="ol" numbered>
                            <ListGroup.Item as="li">
                                Download and open Google Authenticator App on your mobile phone.
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                Scan the QR code shown below to register this application.
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                Enter the six-digit code in the input box and click the button.
                            </ListGroup.Item>
                        </ListGroup>
                        <div className="d-flex justify-content-center position-relative">
                            <Card.Img
                                className="mb-3 w-25 mt-3 p-0"
                                src={data}
                                alt=""
                                style={{boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)"}}
                            />
                            {gettingQRCode ? (
                                <div
                                    style={{
                                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <Spinner animation="border" variant="primary" size="lg"/>
                                </div>
                            ) : null}
                        </div>
                        {error && (
                            <Alert variant="danger" className="d-flex align-items-center p-2">
                                <span className="me-auto">{error}</span>
                                <LoadingButton
                                    onClick={(e) => {
                                        renderQRCode();
                                    }}
                                    loading={gettingQRCode}
                                    className="me-0 mx-auto"
                                >
                                    Refresh QR Code
                                </LoadingButton>
                            </Alert>
                        )}
                        {success && <Alert variant="success">{success}</Alert>}
                        <Form onSubmit={verify2FA}>
                            <Form.Control
                                className="mb-3"
                                type="text"
                                onChange={(event) => {
                                    setCode(event.target.value);
                                }}
                                placeholder="Enter Code from Google Authenticator"
                            />
                        </Form>
                        <LoadingButton
                            style={{
                                marginRight: "10px",
                                width: "100%",
                            }}
                            loading={isVerifying2FA || gettingQRCode}
                            className="w-100 mb-1"
                            onClick={verify2FA}
                        >
                            Verify Code
                        </LoadingButton>
                    </Col>
                </Row>
            </ComponentCard>
        </>
    );
}
