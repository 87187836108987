import React, {Component} from 'react';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/esm/Button';

/**
 * Handles frontend logic relevant to the pricing card component
 */
class PricingCard extends Component {
    /**
     * Class constructor
     * @param props - React props
     */
    constructor(props) {
        super(props);

        this.state = {};
        //this.purchase = this.purchase.bind(this);
    }

    /**
     * Initializes paypal button and backend
     */
    componentDidMount() {
        window.paypal.Buttons({
            createSubscription: function (data, actions) {
                return actions.subscription.create({
                    'plan_id': process.env.REACT_APP_PAYPAL_PLAN_ID
                });
            },
            onApprove: function (data, actions) {
                alert('You have successfully created subscription ' + data.subscriptionID);
            }
        }).render('#paypal-button-container');
    }

    /**
     * Handles a button click for purchasing a plan
     */
    puchase() {

    }

    /**
     * Renders visible components of pricing card
     * @returns - JSX object
     */
    render() {
        return (
            <div>
                <CardGroup>
                    <Card className="text-center pricing-card" style={{color: 'black', border: 'none'}}>
                        <Card.Header>Monthly Plan</Card.Header>
                        <Card.Text className="card-text">Buy it now, $500/month</Card.Text>
                        <Button variant="info" onClick={this.purchase}>Buy Now</Button>
                    </Card>
                    <div id="paypal-button-container"></div>
                </CardGroup>
            </div>);
    }
}

export default PricingCard;
