import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Axios from "axios";
import {BrowserRouter} from "react-router-dom";
import "./components/CustomBootsrap.scss";
import {AuthProvider} from "./contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

(async () => {
    const {publishableKey} = await Axios.get(SERVER_URL + "config").then(
        (response) => {
            return response.data;
        }
    );
    const stripePromise = await loadStripe(publishableKey);

    ReactDOM.render(
        <React.StrictMode>
            <Elements stripe={stripePromise}>
                <AuthProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </AuthProvider>
            </Elements>
        </React.StrictMode>,
        document.getElementById("root")
    );
})();
