import {useRef} from 'react';

export default function FileUploadBox(props) {
    const fileInputRef = useRef();

    return (
        <>
            <input
                ref={fileInputRef}
                onChange={(e) => {
                    props.handleFileUpload(fileInputRef.current.files);
                    e.target.value = null;
                }}
                multiple
                type={'file'}
                style={{display: 'none'}}
            />
            <div
                style={{height: '100%', ...props.style}}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    props.handleFileUpload(e.dataTransfer.files);
                }}
                onClick={() => {
                    fileInputRef.current.click();
                }}
            >
                {props.children}
            </div>
        </>
    );
}
