import React, {Component} from 'react';

import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import PhoneInput from 'react-phone-input-2';

import './Dialog.css';

/**
 * Handles frontend logic relevant to the 2FA dialog
 */
class AuthDialog extends Component {
    /**
     * Initializes handlers and state
     * @param props - React props
     */
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
        this.state = {code: ""};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleResend = this.handleResend.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * Handles submit on 2FA button
     */
    handleSubmit() {
        this.props.checkCode(this.state.code)
    }

    /**
     * Calls cancel2FA from Decryption page on cancel button press
     */
    handleCancel() {
        this.props.cancel();
    }

    /**
     * Calls resend from Decryption page on resend button press
     */
    handleResend() {
        this.props.resend();
    }

    /**
     * Changes the state variables in response to a change to the input fields
     * @param {InputEvent} event - HTML DOM event indicating a change in an input field
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    /**
     * Renders visible components of auth dialog
     * @returns - JSX Object
     */
    render() {
        // console.log(this.props.verificationType)
        if (this.props.verificationType === "phone") {
            return (
                <Row className="m-1">
                    <Row>
                        <Col md={12} sm={12} style={{marginBottom: 10}}>
                            <p className="m-2 mt-0">Please enter the verification code sent to your phone number. </p>
                            <Row className="mb-2">
                                <Col lg={2} md={3} sm={12} xs={12}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <label htmlFor="code-input">
                                        Your number
                                    </label>
                                </Col>
                                <Col lg={8} md={7} sm={12} xs={12}>
                                    <div className="w-100 mx-auto my-1 align-center">
                                        <PhoneInput
                                            className="w-100 phone"
                                            country={"ca"}
                                            value={this.props.value}
                                            disabled={true}
                                            onChange={(number) =>
                                                this.props.change({number}, () => this.props.state)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col lg={2} md={3} sm={12} xs={12}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <label htmlFor="code-input">
                                        Confirmation Code
                                    </label>
                                </Col>
                                <Col lg={8} md={7} sm={12} xs={12}>
                                    <div className="w-100 mx-auto">
                                        <input type="text" className="form-control"
                                               value={this.state.code} name="code"
                                               id="code-input" onChange={this.handleInputChange}/>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <Button style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleResend}>Resend Code</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="m-2" style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleCancel}>Cancel</Button>
                                </Col>
                                <Col>
                                    <Button className="m-2" style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleSubmit}>Submit</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            );
        } else {
            return (
                <Row className="m-1">
                    <Row>
                        <Col md={12} sm={12} style={{marginBottom: 10}}>
                            <p className="m-2 mt-0">Please enter the verification code sent to your email address. </p>
                            <Row className="mb-2">
                                <Col lg={2} md={3} sm={12} xs={12}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <label htmlFor="code-input">
                                        Your Email
                                    </label>
                                </Col>
                                <Col lg={8} md={7} sm={12} xs={12}>
                                    <div className="w-100 mx-auto my-1 align-center">
                                        <input
                                            className="form-control"
                                            placeholder="example@abc.com"
                                            value={this.props.value}
                                            onChange={this.props.change}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col lg={2} md={3} sm={12} xs={12}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <label htmlFor="code-input">
                                        Confirmation Code
                                    </label>
                                </Col>
                                <Col lg={8} md={7} sm={12} xs={12}>
                                    <div className="w-100 mx-auto">
                                        <input type="text" className="form-control"
                                               value={this.state.code} name="code"
                                               id="code-input" onChange={this.handleInputChange}/>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}
                                     style={{display: "flex", alignItems: "center", padding: 0,}}
                                     className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}>
                                    <Button style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleResend}>Resend Code</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="m-2" style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleCancel}>Cancel</Button>
                                </Col>
                                <Col>
                                    <Button className="m-2" style={{
                                        backgroundColor: "#800000",
                                        color: "white",
                                        border: "black",
                                        width: "100%"
                                    }} onClick={this.handleSubmit}>Submit</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
                // <div className="dialog-container p-5"><Row><Col>
                //     <h1>Auth Dialog</h1>
                //     <p>You've been sent a verification code via email to ensure
                //         you are the correct recipient. Please enter the code below.
                //     </p>
                //     <input type="text" className="form-control code-textbox mx-auto"
                //            value={this.state.code} placeholder="123456" name="code"
                //            id="code-input" onChange={this.handleInputChange} />
                //     <Button className="m-2" variant="success" onClick={this.handleSubmit}>Submit</Button>
                //     <Button className="m-2" variant="info" onClick={this.handleResend}>Resend Code</Button>
                //     <Button className="m-2" variant="danger" onClick={this.handleCancel}>Cancel</Button>
                // </Col></Row></div>
            );
        }

    }
}

export default AuthDialog;
