import React, {useState} from "react";
import {Alert, Button, Card} from "react-bootstrap";
import {postWithCredentials, useAuth} from "../contexts/AuthContext";
import {Link} from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ActionPage() {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const {currentUser} = useAuth();

    const sentSMS = async () => {
        const serviceUsable = await checkServiceUsable("messages_sent");
        if (serviceUsable) {
            updateServiceCount("messages_sent");
        } else {
            setError("sentSMS service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };
    const verifySMS = async () => {
        const serviceUsable = await checkServiceUsable("messages_verified");
        if (serviceUsable) {
            updateServiceCount("messages_verified");
        } else {
            setError("verifySMS service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };
    const sentEmail = async () => {
        const serviceUsable = await checkServiceUsable("emails_sent");
        if (serviceUsable) {
            updateServiceCount("emails_sent");
        } else {
            setError("sentEmail service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };
    const verifyEmail = async () => {
        const serviceUsable = await checkServiceUsable("emails_verified");
        if (serviceUsable) {
            updateServiceCount("emails_verified");
        } else {
            setError("verifyEmail service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };
    const encryptFiles = async () => {
        const serviceUsable = await checkServiceUsable("files_encrypted");

        if (serviceUsable) {
            updateServiceCount("files_encrypted");
        } else {
            setError("encryptFiles service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };
    const decryptFiles = async () => {
        const serviceUsable = await checkServiceUsable("files_decrypted");
        if (serviceUsable) {
            updateServiceCount("files_decrypted");
        } else {
            setError("decryptFiles service not usable");
            setTimeout(() => {
                setError("");
            }, 1000);
        }
    };

    const updateServiceCount = async (service) => {
        await postWithCredentials(SERVER_URL + "updateServiceCount", {
            email: currentUser.email,
            id: currentUser.uid,
            service: service,
            count: "1",
        })
            .then((response) => {
                setSuccess(service + " updated");
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const checkServiceUsable = async (service) => {
        var usable;
        await postWithCredentials(SERVER_URL + "checkServiceUsable", {
            userEmail: currentUser.email,
            service: service,
        })
            .then((response) => {
                usable = response.data.usable;
            })
            .catch((err) => {
                // console.log(err);
            });
        return usable;
    };

    return (
        <>
            <Link
                to="/service"
                className="btn btn-outline-primary w-10 m-3 position-absolute top-0 start-0"
            >
                Back
            </Link>
            <Card
                style={{
                    maxWidth: "400px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <Card.Body>
                    <h2 className="text-center mb-4">Action</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Button className="btn btn-primary w-100 mt-3" onClick={sentSMS}>
                        Sent SMS
                    </Button>
                    <Button className="btn btn-primary w-100 mt-3" onClick={verifySMS}>
                        Verify SMS
                    </Button>
                    <Button className="btn btn-primary w-100 mt-3" onClick={sentEmail}>
                        Sent Email
                    </Button>
                    <Button className="btn btn-primary w-100 mt-3" onClick={verifyEmail}>
                        Verify Email
                    </Button>
                    <Button className="btn btn-primary w-100 mt-3" onClick={encryptFiles}>
                        Encrypt Files
                    </Button>
                    <Button className="btn btn-primary w-100 mt-3" onClick={decryptFiles}>
                        Decrypt Files
                    </Button>
                </Card.Body>
            </Card>
        </>
    );
}
