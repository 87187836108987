//import Axios from 'axios';

import {postWithCredentials} from "../contexts/AuthContext";

const Axios = require('axios');
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getUserFromEmail = async (email) => {
    email = email.toLowerCase();
    return await postWithCredentials(SERVER_URL + 'getUserFromEmail', {
        userEmail: email,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            // console.log(err);
            if (err.response.data.error === 'User Not Found') {
                throw new Error('User Not Found');
            } else {
                throw new Error('Error getting user from email');
            }
        });
}

export const getUserFromSecondIndex = async (secondIndex) => {
    return await postWithCredentials(SERVER_URL + 'getUserFromSecondIndex', {
        secondIndex: secondIndex,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            // console.log(err);
            throw new Error('Error getting user from second index');
        });
}

//module.exports = { getUserFromEmail, getUserFromSecondIndex };
