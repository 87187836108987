import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Modal, Row, Stack} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {auth} from "../firebase";
import {getUserFromEmail} from "../functions/getUser";
import {useCookies} from "react-cookie";
import check from "../assets/icons/svg/check.png";
import ComponentCard from "./ComponentCard";
import {postWithCredentials} from "../contexts/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const TRIAL_ID =
    "a992d006602259516f952e359037f82a994540aced68fb7bbb373da94789f09e";
//    "b4c60d2aa3c313d254580aa1249b4ec3c045046fcd84f939895d1c9449675708"; old free trial id

export default function ShowServiceClass() {
    const [productsInfo, setProductsInfo] = useState(null);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState("");
    const [userCanceledService, setUserCanceledService] = useState(false);
    const cookies = useCookies(["name"]);
    const [showModal, setShowModal] = useState(false);

    // get user information
    useEffect(() => {
        setUserData(null);
        getUserFromEmail(auth.currentUser.email)
            .then((data) => {
                // console.log("THIS IS USER DATA", data);
                setUserData(data);
            })
            .catch((err) => {
                console.log("error occurred when getting user data", err);
            });
        getCancelServiceStatus(auth.currentUser.email)
            .then((isCancel) => {
                setUserCanceledService(isCancel);
            })
            .catch((err) => {
                console.log("error occured while getting cancel status", err);
            });
    }, []);

    // get initial products info
    useEffect(() => {
        getUserFromEmail("maintenance@cynorix.com")
            .then(({products}) => {
                setProductsInfo(products);
            })
            .catch((err) => {
                console.log("error when getting products info", err);
                setProductsInfo(null);
            });
    }, []);

    useEffect(() => {
        check_freetrialEligible();
    }, []);

    const navigate = useNavigate();

    ////////
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const check_freetrialEligible = () => {
        postWithCredentials(SERVER_URL + "checkFreetrialEligible", {
            userId: auth.currentUser.uid,
        })
            .then((res) => {
                // console.log(res.data);
                if (res.data.eligible === false) {
                    setShowModal(true);
                }
                // if (res.data.data.freetrial !== "eligible") {
                //   console.log("not good for freetrial");
                //   //setShowModal(true);
                // }
            })
            .catch((err) => {
                console.log("err", err.response.data.errormsg, err);
            });
    };

    const getCancelServiceStatus = async (email) => {
        return await postWithCredentials(SERVER_URL + "checkCancelServiceStatus", {
            userEmail: email,
        })
            .then((response) => {
                return response.data.canceled;
            })
            .catch((err) => {
                // console.log(err);
                //throw new Error();
            });
    };

    const setUserService = async () => {
        const uid = auth.currentUser.uid;
        const userEmail = auth.currentUser.email;
        let subscribed = false;
        await getUserFromEmail(userEmail).then((userData) => {
            // determine if user is in FPP 0 or FPP 1
            // Double checking if user FPP is 1 or not. This would handel the payment
            if (userData.subscription_id && userData.FPP === 1) {
                subscribed = true;
            }
            // if (userData.subscription_id) {
            // 	subscribed = true;
            // 	subId = userData.subscription_id;
            // }
        });

        if (subscribed) {
            // await updateSubscription(subId, Id, uid, userEmail);
            // how did you get here if you're subscribed?
            setTimeout(() => {
                navigate(`/service-usage-info`);
            }, 1000);
        } else {
            //   setTimeout(() => {
            //   	navigate(`/create-subscription/?class_id=${Id}`);
            //   }, 1000);

            /// CP45
            setTimeout(async () => {
                //await createSubscriptionWp(subId, Id, uid, userEmail); // Create new Sub without the payment
                await createSubscriptionWp(TRIAL_ID, uid, userEmail);
                navigate(`/service-usage-info`); // Takes to service component which has to be changed
            }, 1000);
        }
    };

    /// Create New Sub without payment
    const createSubscriptionWp = async (classId, userId, userEmail) => {
        await postWithCredentials(SERVER_URL + "createSubscriptionWp", {
            classId,
            userId,
            userEmail,
            cancellingSubs: false,
        })
            .then((response) => {
                // console.log("Subscription Successfully Updated");
            })
            .catch((err) => {
                // console.log(err);
                setError("Failed to Switch Service, Please try after some time!");
                //throw new Error('Failed to create/update subscription')
            });
    };


    if (userData === null || productsInfo === null) {
        return null;
    }

    const serviceName = productsInfo[TRIAL_ID].subscription_name;

    const helpContent = (
        <p className="mb-1">
            Suppose the user wants to try this encryption service. They can start by
            clicking <b>Try Free Trial</b>. The user will be asked to confirm that
            they want to try the free trial. Afterwards, they will be redirected to
            their <b>Service Usage Information</b> page. The user will have access to
            this free trial until the next month.
        </p>
    );

    return (
        <ComponentCard title="Free Trial" helpContent={helpContent}>
            <Modal show={showModal} dialogClassName="modal-40w">
                <Modal.Header
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Modal.Title>Free Trial Unavailable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are either already using the free trial or you are not eligible
                    for our free trial.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            navigate("/dashboard");
                        }}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="justify-content-center">
                <Col sm={12} md={6} className={"mb-2"}>
                    <Card
                        style={{
                            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                        }}
                    >
                        <Card.Body className="pt-4 pb-0">
                            <Card.Subtitle className="text-uppercase small text-center">
                                <b>Free Trial</b>
                            </Card.Subtitle>
                        </Card.Body>
                        <hr/>
                        <Card.Body>
                            {/* Plan Options */}
                            <Stack className="align-items-start mb-4" gap={3}>
                <span>
                  <img className="icon mx-1" src={check} alt="check"/>
                  Maximum number of encryptions: <b>50</b>
                </span>
                                <span>
                  <img className="icon mx-1" src={check} alt="check"/>
                  SMS & Email
                </span>
                                {/* <span>
                  <img className="icon mx-1" src={check} alt="check" />
                  Email
                </span> */}
                            </Stack>
                            {/* Selection Button */}
                            <Button style={{width: "100%"}} onClick={handleShow}>
                                Try Free Trial
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* I have added cookies too see what would happen if there already exist */}
                    {cookies.name !== "newName" && userData.FPP === 0 && (
                        <div>
                            Click confirm to subscribe to the {" "}
                            <strong>Free Trial</strong>.
                        </div>
                    )}
                    {cookies.name !== "newName" && userData.FPP === 1 && (
                        <div>
                            If you want to switch your subscription tier, please click the
                            confirm button to continue. After confirmation, your current
                            subscription tier will be switched to
                            <strong>{" " + serviceName}</strong> at the beginning of next
                            month.
                        </div>
                    )}
                    {cookies.name !== "newName" && userCanceledService && (
                        <div>
                            <strong>Notice:</strong> Since your subscription for next month
                            was canceled previously, if you confirm to select this class of
                            service, your subscription will be resumed.
                        </div>
                    )}
                    {cookies.name === "newName" && (
                        <div>
                            You have recently changed your subscription status. Please try
                            after one hour.
                        </div>
                    )}
                    {error && <Alert variant="danger">{error}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>

                    {/* Ternary operetator to see if a user has changed subscription recently  */}

                    {cookies.name === "newName" ? (
                        <Button variant="primary" onClick={handleClose}>
                            Go back
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setUserService();
                            }}
                            disabled={userData.FPP === 1}
                        >
                            Confirm
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </ComponentCard>
    );
}
